import { Rating } from '@/types/Rating';
import BarChart from '@liveauctioneers/caterwaul-components/lib/BartChart/BarChart';
import isEqual from 'react-fast-compare';
import React from 'react';

export interface RatingsCounterProps {
    houseRating: Rating;
    updateFilter: Function;
}

const RatingsCounter = (props: RatingsCounterProps) => {
    const { houseRating, updateFilter } = props;
    const { fiveStarReviews, fourStarReviews, oneStarReviews, threeStarReviews, twoStarReviews } = houseRating;

    const starRatings = React.useMemo(
        () => [
            {
                onClick: () => updateFilter('5star'),
                title: '5 Star',
                value: fiveStarReviews,
            },
            {
                onClick: () => updateFilter('4star'),
                title: '4 Star',
                value: fourStarReviews,
            },
            {
                onClick: () => updateFilter('3star'),
                title: '3 Star',
                value: threeStarReviews,
            },
            {
                onClick: () => updateFilter('2star'),
                title: '2 Star',
                value: twoStarReviews,
            },
            {
                onClick: () => updateFilter('1star'),
                title: '1 Star',
                value: oneStarReviews,
            },
        ],
        [fiveStarReviews, fourStarReviews, oneStarReviews, threeStarReviews, twoStarReviews, updateFilter]
    );

    return (
        <BarChart
            data={starRatings}
            rows={5}
            sortBy="labelDesc"
        />
    );
};

export default React.memo<RatingsCounterProps>(RatingsCounter, isEqual);
