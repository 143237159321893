import isEqual from 'react-fast-compare';
import Rank from './Rank';
import React from 'react';
import styled, { css } from 'styled-components';

export interface RankingsProps {
    mobile: boolean;
    modal?: boolean;
    percentiles: any;
}

const CUTOFF = 80;

const acc = {
    name: 'ranking.accName',
    popoverHeader: 'ranking.accPopoverHeader',
    popoverText: 'ranking.accPopoverText',
};

const resp = {
    name: 'ranking.respName',
    popoverHeader: 'ranking.respPopoverHeader',
    popoverText: 'ranking.respPopoverText',
};

const pay = {
    name: 'ranking.payName',
    popoverHeader: 'ranking.payPopoverHeader',
    popoverText: 'ranking.payPopoverText',
};

const ship = {
    name: 'ranking.shipName',
    popoverHeader: 'ranking.shipPopoverHeader',
    popoverText: 'ranking.shipPopoverText',
};

const Rankings = (props: RankingsProps) => {
    const { mobile, modal, percentiles } = props;
    const { accuracy, payment, responsiveness, shipping } = percentiles;
    const showPayment = payment >= CUTOFF;
    const showAccuracy = accuracy >= CUTOFF;
    const showShipping = shipping >= CUTOFF;
    const showResponsiveness = responsiveness >= CUTOFF;

    return (
        <RankingsContainer>
            <StyledRankings
                $isMobile={mobile}
                $isModal={modal}
            >
                {showAccuracy && (
                    <Rank
                        isMobile={mobile}
                        isModal={modal}
                        name={acc.name}
                        popoverHeader={acc.popoverHeader}
                        popoverText={acc.popoverText}
                    />
                )}
                {showResponsiveness && (
                    <Rank
                        isMobile={mobile}
                        isModal={modal}
                        name={resp.name}
                        popoverHeader={resp.popoverHeader}
                        popoverText={resp.popoverText}
                    />
                )}
                {showPayment && (
                    <Rank
                        isMobile={mobile}
                        isModal={modal}
                        name={pay.name}
                        popoverHeader={pay.popoverHeader}
                        popoverText={pay.popoverText}
                    />
                )}
                {showShipping && (
                    <Rank
                        isMobile={mobile}
                        isModal={modal}
                        name={ship.name}
                        popoverHeader={ship.popoverHeader}
                        popoverText={ship.popoverText}
                    />
                )}
            </StyledRankings>
        </RankingsContainer>
    );
};

export default React.memo<RankingsProps>(Rankings, isEqual);

const RankingsContainer = styled.div`
    display: flex;
    flex-grow: 1;
    justify-content: space-evenly;

    @media (max-width: ${({ theme }) => theme.breakpoints.tabletNarrowWidth}) {
        justify-content: center;
        padding-right: 12px;
    }
`;

const StyledRankings = styled.div<{ $isMobile?: boolean; $isModal?: boolean }>`
    ${({ $isMobile, $isModal }) => {
        if ($isModal) {
            return css`
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: flex-start;
                width: 100%;
                margin-left: 50px;

                @media (max-width: ${({ theme }) => theme.breakpoints.tabletWidth}) {
                    justify-content: center;
                }
            `;
        } else if ($isMobile) {
            return css`
                display: flex;
                flex-wrap: wrap;
                justify-content: space-evenly;
                margin-left: 0;
                width: 100%;
            `;
        } else {
            return css`
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: flex-start;
                margin-top: 20px;
                margin-left: 50px;
                width: 100%;

                @media (max-width: 1024px) {
                    justify-content: space-evenly;
                    align-items: center;
                }

                @media (max-width: ${({ theme }) => theme.breakpoints.tabletWidth}) {
                    width: unset;
                    margin-top: 0;
                }
            `;
        }
    }}
`;
