import * as React from 'react';
import { escapeQuotes } from '@/utils/escapeQuotes';
import { getAuctioneerDirectoryUrl, getAuctioneerLogoUrl, getSellerUrl } from '@/utils/urls';
import { injectIntl, IntlShape } from '@liveauctioneers/hammer-ui-core/intl';
import { Rating } from '@/types/Rating';
import { ReviewResponse } from '@/types/Review';
import { safelyEscapeText } from '@/utils/safelyRender';
import { Seller } from '@/types/Seller';
import PageHeaderMeta from '@liveauctioneers/caterwaul-components/lib/PageHeaderMeta/PageHeaderMeta';
import useAddNotificationCountToTitle from '@/hooks/useAddNotificationCountToTitle';

export type RatingsPageHeaderMetaProps = {
    baseUrl: string;
    canonicalURL: string;
    firstReview: string;
    houseRating: Rating;
    intl: IntlShape;
    seller: Seller;
};

const getReviewData = (review: ReviewResponse) => {
    const { bidderFirstName, overall, review: reviewText, reviewHeadline, reviewSubmitted } = review;

    const description = `${reviewHeadline} ${reviewText}`;

    const safeDescription = safelyEscapeText(escapeQuotes(description));
    return `{
            "@context": "https://schema.org",
            "@type": "Review",
            "datePublished": "${reviewSubmitted}",
            "description": "${safeDescription}",
            "author": {
                "@type": "Thing",
                "name": "${bidderFirstName}"
            },
            "reviewRating": {
                "@type": "Rating",
                "ratingValue": "${overall.toFixed(1)}"
            }
        }`;
};

const RatingsPageHeaderMeta = ({
    baseUrl,
    canonicalURL,
    firstReview,
    houseRating,
    intl,
    seller,
}: RatingsPageHeaderMetaProps) => {
    const { overall, reviews, totalReviews } = houseRating;
    const { address, address2, city, country, countryCode, logoId, name, sellerId, stateCode, zip } = seller;
    const { formatMessage, formatNumber } = intl;
    const displayAddress = address2 ? `${address} ${address2}` : `${address}`;
    const safeDisplayAddress = safelyEscapeText(displayAddress);
    const reviewsCount = formatNumber(totalReviews);
    const houseLocation = stateCode || countryCode;
    const reviewSnippet = firstReview?.length > 158 ? `${firstReview.substring(0, 158)}...` : firstReview;

    const sellerUrl = getSellerUrl(sellerId, name);
    const directoryUrl = getAuctioneerDirectoryUrl();

    const logoUrl = getAuctioneerLogoUrl(logoId);

    const title = useAddNotificationCountToTitle(
        formatMessage(
            {
                id: 'review.meta.title',
            },
            {
                houseLocation,
                houseName: name,
                reviewsCount,
            }
        )
    );

    if (!sellerId || !name) {
        return null;
    }

    const description = formatMessage(
        {
            id: 'review.meta.description',
        },
        {
            houseLocation,
            houseName: name,
            reviewsCount,
            reviewSnippet,
        }
    );

    const safeDescription = safelyEscapeText(escapeQuotes(description));

    const reviewsData = Boolean(reviews?.length)
        ? reviews
              .map((review: ReviewResponse) => {
                  return getReviewData(review);
              })
              .join(',\n')
        : '';

    return (
        <PageHeaderMeta
            canonical={canonicalURL}
            description={safeDescription}
            title={title}
        >
            <script type="application/ld+json">
                {`{
                        "@context": "https://schema.org",
                        "@type": "BreadcrumbList",
                        "itemListElement": [{
                                "@type": "ListItem",
                                "item": {
                                    "@id":"${baseUrl}",
                                    "name":"Home"
                                },
                                "position": 1
                            },
                            {
                                "@type": "ListItem",
                                "item": {
                                    "@id":"${directoryUrl}",
                                    "name":"Auction House Directory"
                                },
                                "position": 2
                        },
                        {
                            "@type": "ListItem",
                            "item": {
                                "@id": "${sellerUrl}",
                                "name":"${name}"
                            },
                            "position": 3
                        },{
                            "@type": "ListItem",
                            "item": {
                                "@id": "${canonicalURL}",
                                "name":"Reviews"
                            },
                            "position": 4
                        }]
                    }`}
            </script>

            <script type="application/ld+json">
                {`{
                        "@context": "https://schema.org",
                        "@type": "LocalBusiness",
                        "address": {
                          "@type": "PostalAddress",
                          "addressCountry": "${country}",
                          "addressLocality": "${city}",
                          "addressRegion": "${stateCode}",
                          "streetAddress": "${escapeQuotes(safeDisplayAddress)}",
                          "postalCode": "${zip}"
                        },
                        "description": "${safeDescription}",
                        "name": "${name}",
                        "image": "${logoUrl}",
                        "aggregateRating": {
                            "@type": "AggregateRating",
                            "ratingValue": "${overall}",
                            "reviewCount": "${totalReviews}"
                          },
                        "review": [${reviewsData}],
                        "location": {
                            "@type": "PostalAddress",
                            "addressCountry": "${country}",
                            "addressLocality": "${city}",
                            "addressRegion": "${stateCode}",
                            "streetAddress": "${escapeQuotes(safeDisplayAddress)}",
                            "postalCode": "${zip}"
                          },
                        "logo": "${logoUrl}",
                        "url": "${sellerUrl}"
                    }`}
            </script>
        </PageHeaderMeta>
    );
};

export default injectIntl(RatingsPageHeaderMeta);
