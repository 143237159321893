import * as React from 'react';
import { getAuctioneerLogoUrl } from '@/utils/urls';
import { ReviewComment as ReviewCommentType } from '@/types/Review';
import { Seller } from '@/types/Seller';
import { shortFormatDate } from '@liveauctioneers/caterwaul-components/lib/utils/dates';
import styled from 'styled-components';

const getDisplayTime = (time: string) => shortFormatDate(new Date(time));

export interface ReviewCommentProps {
    comment: ReviewCommentType;
    seller: Seller;
}

const ReviewComment = (props: ReviewCommentProps) => {
    const { comment, seller } = props;

    return (
        <CommentResponseDiv key={comment.commentId}>
            {Boolean(seller.logoId) && (
                <HouseLogoContainer>
                    <HouseLogo
                        alt={`${seller.name} logo`}
                        loading="lazy"
                        src={getAuctioneerLogoUrl(seller.logoId, 84)}
                    />
                </HouseLogoContainer>
            )}
            <CommentContainer>
                <CommentResponseHeader>
                    <CommentDisplayName>{comment.userDisplayName}</CommentDisplayName>
                    <CommentTime>{getDisplayTime(comment.commentSubmitted)}</CommentTime>
                </CommentResponseHeader>
                <CommentText>{comment.comment}</CommentText>
            </CommentContainer>
        </CommentResponseDiv>
    );
};

export default ReviewComment;

const HouseLogoContainer = styled.div`
    background-color: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.grey400};
    height: 44px;
    margin-right: 17px;
    width: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const HouseLogo = styled.img`
    max-width: 42px;
    max-height: 42px;
`;

const CommentResponseDiv = styled.div`
    align-items: flex-start;
    display: flex;
    padding: 13px 0;
`;

const CommentContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 7px;
    max-width: 630px;
`;

const CommentResponseHeader = styled.div`
    @media (min-width: ${({ theme }) => theme.breakpoints.tabletWidthNum + 1}px) and (max-width: 1200px) {
        width: 100%;
    }
`;

const CommentDisplayName = styled.span`
    font-size: 18px;
    font-family: ${({ theme }) => theme.typography.fontSecondary};
`;

const CommentTime = styled.span`
    font-size: 17px;
    color: ${({ theme }) => theme.colors.grey100};
    letter-spacing: 0;
    margin-left: 15px;
    vertical-align: bottom;
    white-space: nowrap;
`;

const CommentText = styled.div`
    font-size: 16px;
    color: ${({ theme }) => theme.colors.grey100};
    letter-spacing: 0;
    line-height: 24px;
    margin-top: 4px;
    max-width: 630px;

    @media (min-width: ${({ theme }) => theme.breakpoints.tabletWidthNum + 1}px) and (max-width: 1200px) {
        width: 100%;
    }
`;
