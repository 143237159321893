import { FormattedMessage } from '@liveauctioneers/hammer-ui-core/intl';
import { HouseData } from '@/types/HousePercentile';
import { Info } from '@liveauctioneers/hammer-ui-core/icons/v2';
import { Rating } from '@/types/Rating';
import { truncateToOneDecimalWithoutRounding } from '@/utils/truncateToOneDecimalWithoutRounding';
import isEqual from 'react-fast-compare';
import Rankings from './Rankings';
import RatingDisplaySmall from '@/components/RatingDisplaySmall/RatingDisplaySmall';
import RatingsCounter from './RatingsCounter';
import React from 'react';
import styled, { css, useTheme } from 'styled-components';
import Tooltip from '@liveauctioneers/caterwaul-components/lib/Tooltip/Tooltip';
import TopBadge from '@/components/Badges/TopBadge';
import useIsNarrowerThan from '@/hooks/useIsNarrowerThan';

export interface RatingsSummaryProps {
    houseRating: Rating;
    modal?: boolean;
    percentiles: HouseData;
    updateFilterFromBarChart: Function;
}

const RatingsSummary = ({ houseRating, modal, percentiles, updateFilterFromBarChart }: RatingsSummaryProps) => {
    const {
        breakpoints: { tabletNarrowWidthNum },
    } = useTheme();

    const mobile = useIsNarrowerThan(tabletNarrowWidthNum + 1);
    const isTopRated = Boolean(percentiles.isTopRated);
    const overall = Boolean(houseRating?.overall) ? truncateToOneDecimalWithoutRounding(houseRating.overall) : 0;
    const showRankings = houseRating.totalReviews >= 25;
    const houseRatingDisplay = houseRating.totalReviews >= 5 ? houseRating.overall : 0;

    return (
        <StyledInnerContainer>
            <StyledOverview>
                {isTopRated && (
                    <div>
                        <StyledTopBadgeContainer>
                            <TopBadge />
                        </StyledTopBadgeContainer>
                    </div>
                )}
                <div>
                    <RatingDisplaySmall
                        hideReviewCount
                        rating={houseRatingDisplay}
                    />
                </div>
                <StyledOverallRating $isModal={modal}>
                    {houseRating.totalReviews >= 5 ? (
                        <FormattedMessage
                            id="review.overall_rating_label"
                            values={{ rating: overall }}
                        />
                    ) : (
                        <FormattedMessage id="review.not_enough_data" />
                    )}
                    {!modal && (
                        <Tooltip
                            placement="bottom"
                            trigger={
                                <Info
                                    className="ml-2xs mt-[1px]"
                                    color="primary"
                                    size="sm"
                                    style="fill"
                                />
                            }
                        >
                            <FormattedMessage id="review.ratings_explanation" />
                        </Tooltip>
                    )}
                </StyledOverallRating>
            </StyledOverview>
            <StyledRatingsCount $isModal={modal}>
                <RatingsCounter
                    houseRating={houseRating}
                    updateFilter={updateFilterFromBarChart}
                />
            </StyledRatingsCount>
            <StyledRankings>
                {Boolean(showRankings) && (
                    <Rankings
                        mobile={mobile}
                        modal={modal}
                        percentiles={percentiles}
                    />
                )}
            </StyledRankings>
        </StyledInnerContainer>
    );
};

export default React.memo<RatingsSummaryProps>(RatingsSummary, isEqual);

const StyledInnerContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    margin-bottom: 24px;

    @media (max-width: 1024px) {
        flex-wrap: wrap;
        justify-content: space-evenly;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.tabletNarrowWidth}) {
        height: auto;
        flex-direction: column;

        > * {
            width: 100%;
        }
    }
`;

const StyledTopBadgeContainer = styled.div`
    margin-bottom: 15px;
`;

const StyledOverview = styled.div`
    display: flex;
    flex-direction: column;
    height: 120px;

    .topBadge {
        height: 24px;
        margin: 4px 1px 0 0;
    }

    .topBadgeWrapper {
        align-items: center;
        align-self: center;
        display: flex;
        height: 36px;
        margin-top: 5px;

        @media (min-width: ${({ theme }) => theme.breakpoints.tabletNarrowWidthNum + 1}px) and (max-width: 1024px) {
            margin-top: 0;
        }
    }

    .top {
        font-family: ${({ theme }) => theme.typography.fontPrimary};
        font-size: 14px;
        height: 36px;
        letter-spacing: 1px;
        color: ${({ theme }) => theme.colors.orange100};
        vertical-align: text-bottom;
        white-space: nowrap;
    }

    .modalTop {
        @media (min-width: ${({ theme }) => theme.breakpoints.tabletNarrowWidthNum + 1}px) and (max-width: 1000px) {
            font-size: 14px;
        }
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.tabletNarrowWidth}) {
        align-items: center;
        margin-top: 20px;
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.tabletWidthNum + 1}px) and (max-width: 1200px) {
        min-width: 20%;
    }
`;

const StyledRatingsCount = styled.div<{ $isModal: boolean }>`
    margin: 11px 37px 0 69px;
    max-width: 360px;
    flex: 1 0 auto;

    @media (max-width: ${({ theme }) => theme.breakpoints.tabletWidth}) {
        margin: 11px 10px 0 69px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.tabletNarrowWidth}) {
        margin: 11px 0 0;
    }

    ${({ $isModal }) =>
        $isModal &&
        css`
            margin: 11px 15px 0;
        `}
`;

const StyledOverallRating = styled.div<{ $isModal: boolean }>`
    font-family: ${({ theme }) => theme.typography.fontSecondary};
    margin-top: 21px;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.black};
    display: flex;
    flex-direction: row;
    ${({ $isModal }) =>
        $isModal &&
        css`
            @media (min-width: ${({ theme }) => theme.breakpoints.tabletNarrowWidthNum + 1}px) and (max-width: 1000px) {
                font-size: 12px;
            }
        `}
`;

const StyledRankings = styled.div`
    flex-grow: 1;

    @media (max-width: 1024px) {
        margin: 20px 0 0 0;
    }

    @media (max-width: 1024px) {
        margin: 0;
    }

    @media (max-width: 350px) {
        width: 300px;
    }
`;
