import { FormattedMessage } from '@liveauctioneers/hammer-ui-core/intl';
import { ThumbsUp } from '@liveauctioneers/hammer-ui-core/icons/v2';
import isEqual from 'react-fast-compare';
import React, { MouseEvent } from 'react';
import styled from 'styled-components';
import Throbber from '@liveauctioneers/caterwaul-components/lib/Throbber/Throbber';

export interface HelpfulProps {
    active: boolean;
    className?: string;
    count: number;
    onClick: (event: MouseEvent<HTMLButtonElement>) => void;
    submitted: boolean;
}

const Helpful = (props: HelpfulProps) => {
    const { active, className, count, onClick, submitted } = props;
    const icon = active ? <ThumbsUp style="fill" /> : <ThumbsUp />;

    if (submitted) {
        return <LoadingThrobber />;
    }

    return (
        <StyledButton
            className={className}
            disabled={submitted}
            onClick={onClick}
        >
            <FormattedMessage id="review.helpful" />
            {icon} {count}
        </StyledButton>
    );
};

export default React.memo<HelpfulProps>(Helpful, isEqual);

const StyledButton = styled.button`
    color: ${({ theme }) => theme.colors.blue100};
    background: none;
    border: none;
    outline: none;
    display: flex;
    padding-left: 0;

    &:hover {
        color: ${({ theme }) => theme.colors.blue200};
    }
`;

const LoadingThrobber = styled(Throbber)`
    padding-right: 91px;
`;
