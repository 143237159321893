import { FormattedMessage } from '@liveauctioneers/hammer-ui-core/intl';
import React from 'react';
import styled, { css } from 'styled-components';
import Tooltip, { Header, Message } from '@liveauctioneers/caterwaul-components/lib/Tooltip/Tooltip';

export interface RankProps {
    isMobile: boolean;
    isModal: boolean;
    name: string;
    popoverHeader: string;
    popoverText: string;
}

const Rank = (props: RankProps) => {
    const {
        isMobile,
        isModal,
        name = 'ranking.accName',
        popoverHeader = 'header',
        popoverText = 'popoverText',
    } = props;

    return (
        <TrophyWrapper $isMobile={isMobile}>
            <Tooltip
                placement="bottom"
                trigger={
                    <StyledBadge>
                        <StyledTrophyContainer>
                            <StyledTrophyImg
                                alt="trophy"
                                height="28"
                                src="https://p1.liveauctioneers.com/dist/images/trophy.svg"
                                width="28"
                            />
                        </StyledTrophyContainer>
                        <StyledBadgeName $isModal={isModal}>
                            <FormattedMessage id={name} />
                        </StyledBadgeName>
                    </StyledBadge>
                }
            >
                <Header>
                    <FormattedMessage id={popoverHeader} />
                </Header>
                <Message last>
                    <FormattedMessage id={popoverText} />
                </Message>
            </Tooltip>
        </TrophyWrapper>
    );
};

export default Rank;

const StyledBadgeName = styled.span<{ $isModal: boolean }>`
    font-size: 15px;
    font-family: ${({ theme }) => theme.typography.fontSecondary};
    line-height: 20px;
    letter-spacing: 0.5px;
    margin-top: 7px;
    text-align: center;
    display: inline-block;

    @media (max-width: ${({ theme }) => theme.breakpoints.tabletNarrowWidth}) {
        margin-bottom: 10px;
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.tabletWidthNum + 1}px) and (max-width: 1200px) {
        display: inline;
    }

    ${({ $isModal }) =>
        $isModal &&
        css`
            @media (min-width: ${({ theme }) => theme.breakpoints.tabletNarrowWidthNum + 1}px) and (max-width: 1000px) {
                font-size: 9px;
            }
        `}
`;

const StyledBadge = styled.div`
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    width: 85px;
    cursor: pointer;

    @media (min-width: ${({ theme }) => theme.breakpoints.tabletWidthNum + 1}px) and (max-width: 1200px) {
        width: 16%;
    }
`;

const StyledTrophyContainer = styled.div`
    align-items: center;
    display: flex;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    border: 1px solid ${({ theme }) => theme.colors.grey400};
    justify-content: center;
    margin-bottom: 5px;

    @media (min-width: ${({ theme }) => theme.breakpoints.tabletWidthNum + 1}px) and (max-width: 1200px) {
        height: 6vw;
        width: 6vw;
    }
`;

const StyledTrophyImg = styled.img`
    width: 28px;
    height: 28px;
    margin-top: 4px;
    @media (min-width: ${({ theme }) => theme.breakpoints.tabletWidthNum + 1}px) and (max-width: 1200px) {
        width: 4vw;
        height: 4vw;
        margin-top: 4px;
    }
`;

const TrophyWrapper = styled.div<{ $isMobile?: boolean }>`
    ${({ $isMobile }) => {
        if ($isMobile) {
            return css`
                justify-self: center;
                margin: 0 15px;

                @media (max-width: ${({ theme }) => theme.breakpoints.mobileWidth}) {
                    margin: 12px;
                }
            `;
        } else {
            return css`
                margin: 15px 28px;
                margin-top: 0;

                @media (max-width: 1200px) {
                    width: 100px;
                    margin: 15px 0;
                }
                @media (max-width: 1024px) {
                    max-width: 70px;
                    margin: 15px 0;
                }

                @media (max-width: ${({ theme }) => theme.breakpoints.tabletWidth}) {
                    max-width: unset;
                    margin: 15px 6px;
                }
            `;
        }
    }}
`;
