import { Component } from 'react';
import { FormattedMessage, FormattedNumber } from '@liveauctioneers/hammer-ui-core/intl';
import { ReviewComment, ReviewResponse } from '@/types/Review';
import { Select, SelectOnChange } from '@liveauctioneers/hammer-ui-core/select';
import { Seller } from '@/types/Seller';
import isEqual from 'react-fast-compare';
import Link from '@liveauctioneers/caterwaul-components/lib/Link/Link';
import Pagination from '@/components/Pagination/hammer-pagination/Pagination';
import Review from './Review';
import styled from 'styled-components';

const VIEWS_TO_SHOW = [10, 25, 50, 100];

export interface ReviewsProps {
    dismissReviewsModal: Function;
    filter: string;
    filteredCount: number;
    fullPage: boolean;
    fullPageLink: string;
    helpfulCounts: Record<number, number>;
    onChange: Function;
    page: number;
    pageSize: number;
    reviewComments: ReviewComment[];
    reviews: ReviewResponse[];
    seller: Seller;
    sort: string;
    statuses: Record<number, string[]>;
    submitted: boolean;
    totalReviews: number;
    updateFilter: SelectOnChange;
    updateSort: SelectOnChange;
}

export default class Reviews extends Component<ReviewsProps> {
    shouldComponentUpdate = (nextProps: ReviewsProps) => !isEqual(this.props, nextProps);

    filterOptions = [
        { label: 'All Ratings', value: '' },
        { label: '5 Star Only', value: '5star' },
        { label: '4 Star Only', value: '4star' },
        { label: '3 Star Only', value: '3star' },
        { label: '2 Star Only', value: '2star' },
        { label: '1 Star Only', value: '1star' },
    ];

    sortOptions = [
        { label: 'Most Recent', value: '-created' },
        { label: 'Oldest', value: 'created' },
        { label: 'Highest Rated', value: '-overall' },
        { label: 'Lowest Rated', value: 'overall' },
    ];

    getPageEnds() {
        const { filteredCount, page, pageSize } = this.props;
        let start = (page - 1) * pageSize;
        let end = start + pageSize;

        if (end > filteredCount) {
            end = filteredCount;
        }

        if (start === 0 && end !== 0) {
            start = 1;
        }

        return { end, start };
    }

    closeModal = () => {
        this.props.dismissReviewsModal();
    };

    render() {
        const {
            filter,
            filteredCount,
            fullPage,
            fullPageLink,
            helpfulCounts,
            onChange,
            page,
            pageSize,
            reviewComments,
            reviews,
            seller,
            sort,
            statuses,
            submitted,
            totalReviews,
            updateFilter,
            updateSort,
        } = this.props;
        const commentsByReview = {};
        if (reviewComments) {
            Object.keys(reviewComments).forEach((k) => {
                commentsByReview[reviewComments[k].reviewId]
                    ? commentsByReview[reviewComments[k].reviewId].unshift(reviewComments[k])
                    : (commentsByReview[reviewComments[k].reviewId] = [reviewComments[k]]);
            });
        }
        const reviewDisplays = reviews
            ? reviews.map((houseReview, key) => (
                  <Review
                      comments={commentsByReview[houseReview.reviewId] || []}
                      helpfulCount={helpfulCounts[houseReview.reviewId] || 0}
                      houseReview={houseReview}
                      key={key}
                      seller={seller}
                      statuses={statuses[houseReview.reviewId] || []}
                      submitted={submitted}
                  />
              ))
            : undefined;

        const latestTotalFormatted = (
            <FormattedNumber
                key="latest_total_formatted"
                value={reviews?.length || 0}
            />
        );
        const totalFormatted = (
            <FormattedNumber
                key="total_formatted"
                value={filteredCount || 0}
            />
        );
        const { end, start } = this.getPageEnds();
        const startFormatted = (
            <FormattedNumber
                key="start_count"
                value={start}
            />
        );
        const endFormatted = (
            <FormattedNumber
                key="end_count"
                value={end}
            />
        );

        const title = fullPage ? (
            <FormattedMessage
                id="review.full_page_review_title"
                values={{
                    count: totalFormatted,
                    end: endFormatted,
                    start: startFormatted,
                }}
            />
        ) : reviews?.length === 1 ? (
            <>
                <FormattedMessage id="review.latest_review" />
                <SeeAllLink
                    onClick={this.closeModal}
                    route
                    to={fullPageLink}
                >
                    <FormattedMessage id="review.see_all" />
                </SeeAllLink>
            </>
        ) : (
            Boolean(reviews?.length) && (
                <>
                    <FormattedMessage
                        id="review.latest_reviews"
                        values={{ count: latestTotalFormatted }}
                    />
                    <SeeAllLink
                        onClick={this.closeModal}
                        route
                        to={fullPageLink}
                    >
                        <FormattedMessage id="review.see_all" />
                    </SeeAllLink>
                </>
            )
        );

        const fullPageView = (
            <div>
                <StyledHeader id="reviews">
                    <StyledH2>{title}</StyledH2>
                </StyledHeader>
                <Pagination
                    filter={
                        <StyledPaginationControls>
                            <Select
                                className="mb-xs w-full max-w-[260px] xsMax:max-w-full"
                                isInsideLabel
                                labelText={
                                    <>
                                        <FormattedMessage id="filter" />:
                                    </>
                                }
                                name="filter"
                                onChange={(value) => updateFilter(value)}
                                options={this.filterOptions}
                                small
                                value={this.filterOptions.find((op) => op.value === filter)}
                            />
                            <Select
                                className="mb-xs w-full max-w-[260px] xsMax:max-w-full"
                                isInsideLabel
                                labelText={
                                    <>
                                        <FormattedMessage id="sort" />:
                                    </>
                                }
                                name="sort"
                                onChange={(value) => updateSort(value)}
                                options={this.sortOptions}
                                small
                                value={this.sortOptions.find((op) => op.value === sort)}
                            />
                        </StyledPaginationControls>
                    }
                    onChange={onChange}
                    pageSizeOptions={VIEWS_TO_SHOW}
                    paginationFilter={{
                        page: page,
                        pageSize: pageSize,
                        totalRecords: totalReviews,
                    }}
                >
                    {reviewDisplays}
                </Pagination>
            </div>
        );

        return fullPage ? (
            fullPageView
        ) : (
            <div>
                <StyledPartialViewH2>{title}</StyledPartialViewH2>
                {reviewDisplays}
            </div>
        );
    }
}

const StyledHeader = styled.div`
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey400};
    border-top: 1px solid ${({ theme }) => theme.colors.grey400};
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    margin-top: 10px;
    padding: 30px 0 30px;

    @media (max-width: ${({ theme }) => theme.breakpoints.tabletNarrowWidth}) {
        flex-direction: column;
        align-items: center;
    }
`;

const StyledH2 = styled.h2`
    margin: 6px 0 0 0;
`;

const StyledPartialViewH2 = styled.h2`
    margin: 61px 0 55px;
`;

const StyledPaginationControls = styled.div`
    display: flex;
    flex-direction: column;

    @media (max-width: ${({ theme }) => theme.breakpoints.tabletNarrowWidth}) {
        align-items: center;
    }
`;

const SeeAllLink = styled(Link)`
    font-family: ${({ theme }) => theme.typography.fontPrimary};
    display: inline-block;
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.grey100};
    font-size: 12px;
    letter-spacing: 0.5px;
    margin: 0 0 3px 10px;

    &:hover {
        color: ${({ theme }) => theme.colors.blue200};
    }
`;
