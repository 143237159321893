import { FormattedMessage } from '@liveauctioneers/hammer-ui-core/intl';
import isEqual from 'react-fast-compare';
import Link from '@liveauctioneers/caterwaul-components/lib/Link/Link';
import React from 'react';

export interface ReportProps {
    active: boolean;
    className?: string;
    onClick: Function;
}

const Report = (props: ReportProps) => {
    const { active, className, onClick } = props;
    const id = active ? 'review.reported' : 'review.report';
    return (
        <Link
            className={className}
            onClick={onClick}
        >
            <FormattedMessage id={id} />
        </Link>
    );
};

export default React.memo<ReportProps>(Report, isEqual);
