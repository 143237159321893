import * as React from 'react';
import { Crumb } from '@liveauctioneers/caterwaul-components/lib/Breadcrumbs/Breadcrumbs';
import { getAuctioneerDirectoryUrl, getSellerUrl } from '@/utils/urls';
import { useIntl } from '@liveauctioneers/hammer-ui-core/intl';
import BreadcrumbWrapper from '@/components/Breadcrumbs/BreadcrumbWrapper';
import styled from 'styled-components';

export interface RatingsPageBreadcrumbsProps {
    sellerId: number;
    sellerName: string;
}

const RatingsPageBreadcrumbs = ({ sellerId, sellerName }: RatingsPageBreadcrumbsProps) => {
    const { formatMessage } = useIntl();

    const crumbs = React.useMemo(
        () =>
            [
                { label: formatMessage({ id: 'online_auctions' }), link: '/' },
                {
                    label: formatMessage({ id: 'auction_house_directory' }),
                    link: getAuctioneerDirectoryUrl(),
                },
            ] as Crumb[],
        [formatMessage]
    );

    if (sellerName) {
        crumbs.push({
            label: sellerName,
            link: getSellerUrl(sellerId, sellerName),
        });
        crumbs.push({ label: formatMessage({ id: 'review.all_reviews' }) });
    }
    return <StyledBreadcrumbWrapper crumbs={crumbs} />;
};

export default React.memo<RatingsPageBreadcrumbsProps>(RatingsPageBreadcrumbs);

const StyledBreadcrumbWrapper = styled(BreadcrumbWrapper)`
    margin: 32px auto 0;
`;
