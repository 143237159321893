import { Component } from 'react';
import { FormattedMessage, FormattedNumber, injectIntl, IntlShape } from '@liveauctioneers/hammer-ui-core/intl';
import { getSellerReviewsUrl, getSellerUrl } from '@/utils/urls';
import { HouseData } from '@/types/HousePercentile';
import { Rating } from '@/types/Rating';
import { ReviewComment } from '@/types/Review';
import { RouteButton } from '@/components/RouteButton/RouteButton';
import { Seller } from '@/types/Seller';
import isEqual from 'react-fast-compare';
import Link from '@liveauctioneers/caterwaul-components/lib/Link/Link';
import PageTitle from '@/components/PageTitle/PageTitle';
import RatingsPageBreadcrumbs from './RatingsPageBreadcrumbs';
import RatingsPageHeaderMeta from './RatingsPageHeaderMeta';
import RatingsSummary from './RatingsSummary';
import Reviews from './Reviews';
import scrollIntoView from 'scroll-into-view';
import styled, { css } from 'styled-components';

export interface RatingsPageProps {
    baseUrl: string;
    dismissReviewsModal?: () => void;
    fetchBidderStatusesByIdIfNeeded: Function;
    fetchHelpfulCountsByIdIfNeeded: Function;
    fullPage: boolean;
    helpfulCounts: Record<number, number>;
    houseRating: Rating;
    intl: IntlShape;
    loadSellerRatingsById: Function;
    modal?: boolean;
    navigate: Function;
    percentiles: HouseData;
    queryParams?: any;
    reviewComments: ReviewComment[];
    seller: Seller;
    sellerId: number;
    statuses: Record<number, string[]>;
    submitted?: boolean;
    whiteLabel?: boolean;
}

type State = {
    filter: string;
    page: number;
    pageSize: number;
    sort: string;
};

class RatingsPage extends Component<RatingsPageProps, State> {
    static defaultProps = {
        dismissReviewsModal: () => null,
    };

    state = {
        filter: !this.props.fullPage ? 'hasreview' : this.props.queryParams?.filter || '',
        page: this.props.queryParams?.page || 1,
        pageSize: this.props.fullPage ? this.props.queryParams?.pageSize || 50 : 8,
        sort: this.props.queryParams?.sort || '-overall',
    };

    componentDidUpdate(prevProps: RatingsPageProps, prevState: State) {
        const { houseRating, statuses } = this.props;
        const { filter, page, pageSize, sort } = this.state;
        if (this.shouldRedirect()) {
            this.redirectToSellerPage();
        }
        const sellerId = this.props.sellerId;
        if (
            prevState.filter !== filter ||
            prevState.page !== page ||
            prevState.pageSize !== pageSize ||
            prevState.sort !== sort
        ) {
            this.props.loadSellerRatingsById({
                filter,
                page,
                pageSize,
                sellerId,
                sort,
            });
        }
        const statusCount = Object.keys(statuses).length;
        if (Boolean(houseRating?.reviews?.length)) {
            if (!statusCount || !isEqual(prevProps.houseRating, houseRating)) {
                const ids = houseRating.reviews.map((r) => r.reviewId);
                this.props.fetchBidderStatusesByIdIfNeeded(ids);
                this.props.fetchHelpfulCountsByIdIfNeeded(ids);
            }
        }
    }

    shouldRedirect = () => {
        const { fullPage, houseRating } = this.props;
        const notAllowed = Boolean(houseRating?.houseId) && !houseRating?.allowPublicReviews;

        return fullPage && notAllowed;
    };

    redirectToSellerPage = () => {
        const { seller } = this.props;
        const { name, sellerId } = seller;
        const sellerUrl = getSellerUrl(sellerId, name);
        if (Boolean(window)) {
            // @ts-ignore
            window.location = sellerUrl;
        }
    };

    scrollToReviews = () => {
        const reviewsSection = document.getElementById('reviews');
        scrollIntoView(reviewsSection, {
            align: {
                top: 0,
            },
        });
    };

    updateFilter = ({ value }: any) => this.setState({ filter: value, page: 1 });

    updateFilterFromBarChart = (filter: string) => {
        if (this.props.fullPage) {
            this.setState({ filter, page: 1 });
            this.scrollToReviews();
        } else {
            const { seller } = this.props;
            const { name, sellerId } = seller;
            const reviewsUrl = getSellerReviewsUrl(sellerId, name);

            if (!this.props.modal) {
                this.props.navigate(
                    {
                        pathname: reviewsUrl,
                    },
                    { replace: true }
                );
            }

            this.setState({ filter, page: 1 });
            this.scrollToReviews();
        }
    };

    updateSort = ({ value }: any) => this.setState({ page: 1, sort: value });

    onChange = (pageInfo: any) => {
        this.setState(pageInfo);
        this.scrollToReviews();
    };

    render() {
        const {
            baseUrl,
            dismissReviewsModal,
            fullPage,
            helpfulCounts,
            houseRating,
            intl,
            modal,
            percentiles,
            reviewComments,
            seller,
            statuses,
            submitted,
            whiteLabel,
        } = this.props;

        const { filter, page, pageSize, sort } = this.state;
        const { formatMessage } = intl;

        const { name, sellerId } = seller;
        const totalReviews = (
            <FormattedNumber
                key="total_reviews"
                value={houseRating?.totalReviews || 0}
            />
        );
        const fullPageLink = getSellerReviewsUrl(sellerId, name);
        const canonicalURL = fullPageLink.split('?')[0];

        const reviewsCountLink = fullPage ? (
            <Link
                onClick={this.scrollToReviews}
                route
            >
                <StyledParenthesisContainer>(</StyledParenthesisContainer>
                {totalReviews}
                <StyledParenthesisContainer>)</StyledParenthesisContainer>
            </Link>
        ) : (
            <Link
                route
                to={fullPageLink}
            >
                <StyledParenthesisContainer>(</StyledParenthesisContainer>
                {totalReviews}
                <StyledParenthesisContainer>)</StyledParenthesisContainer>
            </Link>
        );

        const pageHeader = (
            <>
                <FormattedMessage
                    id="review.bidder_reviews_name"
                    values={{ name }}
                />{' '}
                {reviewsCountLink}
            </>
        );

        const header = fullPage ? (
            <StyledFullPageReviewHeader>
                <PageTitle title={pageHeader}></PageTitle>
            </StyledFullPageReviewHeader>
        ) : (
            <StyledReviewHeader>
                <StyledSellerHeader>{pageHeader}</StyledSellerHeader>
            </StyledReviewHeader>
        );

        const reviews = (
            <Reviews
                dismissReviewsModal={dismissReviewsModal}
                filter={filter}
                filteredCount={houseRating.filteredReviewCount}
                fullPage={fullPage}
                fullPageLink={fullPageLink}
                helpfulCounts={helpfulCounts}
                onChange={this.onChange}
                page={page}
                pageSize={pageSize}
                reviewComments={reviewComments}
                reviews={houseRating.reviews}
                seller={seller}
                sort={sort}
                statuses={statuses}
                submitted={submitted}
                totalReviews={houseRating.totalReviews}
                updateFilter={this.updateFilter}
                updateSort={this.updateSort}
            />
        );

        const shouldRender = Boolean(houseRating.allowPublicReviews);

        const withWrittenReviews =
            houseRating?.reviews?.length > 0
                ? houseRating.reviews.filter((review) => {
                      return review.review.length > 0;
                  })
                : [{ review: '' }];

        let firstReview = '';
        if (Boolean(withWrittenReviews[0]?.review)) {
            firstReview = withWrittenReviews[0]?.review;
        }
        return (
            shouldRender && (
                <>
                    {fullPage && (
                        <div>
                            <RatingsPageHeaderMeta
                                baseUrl={baseUrl}
                                canonicalURL={canonicalURL}
                                firstReview={firstReview}
                                houseRating={houseRating}
                                seller={seller}
                            />
                            {!whiteLabel && (
                                <RatingsPageBreadcrumbs
                                    sellerId={seller.sellerId}
                                    sellerName={seller.name}
                                />
                            )}
                        </div>
                    )}
                    <StyledContainer
                        $isFullPage={fullPage}
                        $isModal={modal}
                    >
                        {fullPage}
                        {header}
                        <RatingsSummary
                            houseRating={houseRating}
                            modal={modal}
                            percentiles={percentiles}
                            updateFilterFromBarChart={this.updateFilterFromBarChart}
                        />
                        {reviews}
                        {!fullPage && (
                            <StyledButtonContainer>
                                <RouteButton
                                    onClick={dismissReviewsModal}
                                    to={fullPageLink}
                                >
                                    {houseRating?.totalReviews !== 1
                                        ? formatMessage({ id: 'review.page_link_button' }, { count: totalReviews })
                                        : formatMessage({
                                              id: 'review.page_link_button_singular',
                                          })}
                                </RouteButton>
                            </StyledButtonContainer>
                        )}
                    </StyledContainer>
                </>
            )
        );
    }
}

export default injectIntl(RatingsPage);

const StyledParenthesisContainer = styled.span`
    display: inline-block;
    background-color: ${({ theme }) => theme.colors.white};
`;

const StyledContainer = styled.div<{ $isFullPage: boolean; $isModal: boolean }>`
    max-width: 1200px;
    margin: 70px auto 0;
    width: calc(100% - 40px);
    align-self: center;
    margin-bottom: 30px;

    @media (max-width: 350px) {
        width: calc(100% - 10px);
    }

    ${({ $isFullPage, $isModal }) => {
        if ($isModal) {
            return css`
                margin: 45px auto;
                padding-left: 15px;
                padding-right: 15px;

                @media (max-width: ${({ theme }) => theme.breakpoints.tabletNarrowWidth}) {
                    padding-left: 0;
                    padding-right: 0;
                }
            `;
        } else if ($isFullPage) {
            return css`
                margin: 0 auto;

                @media (max-width: 350px) {
                    width: calc(100% - 40px);
                }
            `;
        }
    }}
`;

const StyledButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 35px;
`;

const StyledFullPageReviewHeader = styled.div`
    font-size: 28px;
    letter-spacing: 0.56px;
    margin-bottom: 24px;

    span {
        margin-right: 1px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.tabletNarrowWidth}) {
        font-size: 24px;
    }
`;

const StyledReviewHeader = styled.div`
    font-size: 28px;
    letter-spacing: 0.56px;
    margin-bottom: 24px;

    @media (max-width: ${({ theme }) => theme.breakpoints.tabletNarrowWidth}) {
        font-size: 24px;
    }
`;

const StyledSellerHeader = styled.h2`
    .sellerPageHeader {
        margin: 20px 0 27px;
        text-align: left;
        font-size: 28px;

        @media (max-width: ${({ theme }) => theme.breakpoints.tabletNarrowWidthNum - 1}px) {
            margin: 20px 0 15px;
            font-size: 20px;
        }

        span {
            font-family: ${({ theme }) => theme.typography.fontSecondary};
        }
    }
`;
