import * as React from 'react';
import { ReviewComment as ReviewCommentType } from '@/types/Review';
import { Seller } from '@/types/Seller';
import ReviewComment from './ReviewComment';
import ReviewFeedbackBar from './ReviewFeedbackBar';
import styled from 'styled-components';
import Text from '@liveauctioneers/caterwaul-components/lib/Text/Text';

interface ReviewAndCommentsProps {
    comments: ReviewCommentType[];
    helpfulCount: number;
    review: string;
    reviewHeadline: string;
    reviewId: number;
    seller: Seller;
    statuses: string[];
    submitted: boolean;
}

const ReviewAndComments = (props: ReviewAndCommentsProps) => {
    const { comments, helpfulCount, review, reviewHeadline, reviewId, seller, statuses } = props;

    const publicComments = comments
        .filter((rc: ReviewCommentType) => rc.privacyStatus === 'public')
        .map((rc: ReviewCommentType, i: number) => {
            return (
                <ReviewComment
                    comment={rc}
                    key={i}
                    seller={seller}
                />
            );
        });

    const reviewTitle = Boolean(reviewHeadline) ? <HeadLineText h5>{reviewHeadline}</HeadLineText> : null;
    const reviewTextContent = Boolean(review) ? <ReviewTextContent>{review}</ReviewTextContent> : null;

    return (
        <PublicReview>
            {reviewTitle}
            {reviewTextContent}
            {(review?.length > 0 || reviewHeadline?.length > 0) && (
                <ReviewFeedbackBar
                    helpfulCount={helpfulCount}
                    reviewId={reviewId}
                    statuses={statuses}
                />
            )}
            {publicComments.length ? publicComments : null}
        </PublicReview>
    );
};

export default ReviewAndComments;

const ReviewTextContent = styled.div`
    letter-spacing: 0;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.grey100};
    font-size: 16px;
    margin: 4px 0 5px;
    max-width: 688px;

    @media (min-width: ${({ theme }) => theme.breakpoints.tabletWidthNum + 1}px) and (max-width: 1200px) {
        width: 100%;
    }
`;

const PublicReview = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
`;

const HeadLineText = styled(Text)`
    letter-spacing: 0;
    margin-top: 2px;
    max-width: 688px;

    @media (min-width: ${({ theme }) => theme.breakpoints.tabletWidthNum + 1}px) and (max-width: 1200px) {
        width: 80%;
    }
`;
