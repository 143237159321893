import * as React from 'react';
import Breadcrumbs, { Crumb } from '@liveauctioneers/caterwaul-components/lib/Breadcrumbs/Breadcrumbs';
import isEqual from 'react-fast-compare';
import styled, { useTheme } from 'styled-components';
import useIsNarrowerThan from '@/hooks/useIsNarrowerThan';

type Props = {
    className?: string;
    crumbs: Crumb[];
};

// This breadcrumb wrapper is to provide consistent styles and less code duplication for breadcrumbs that are used
// across the app, except for the item page.

const BreadcrumbWrapper = ({ className, crumbs }: Props) => {
    const {
        breakpoints: { tabletNarrowWidthNum },
    } = useTheme();
    const narrow = useIsNarrowerThan(tabletNarrowWidthNum + 1);

    if (!narrow) {
        return (
            <StyledBreadcrumbs
                className={className}
                crumbs={crumbs}
            />
        );
    }

    return (
        <StyledBreadcrumbWrapper className={className}>
            <StyledBreadcrumbScrollingContainer>
                <Breadcrumbs crumbs={crumbs} />
            </StyledBreadcrumbScrollingContainer>
        </StyledBreadcrumbWrapper>
    );
};

export default React.memo<Props>(BreadcrumbWrapper, isEqual);

const StyledBreadcrumbs = styled(Breadcrumbs)`
    margin: 32px auto;
    width: calc(100% - 40px);
    max-width: 1200px;
`;

const StyledBreadcrumbWrapper = styled.div`
    max-width: 1200px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 1;
    height: 18px;
    overflow: hidden;
    margin: 32px 20px;
`;

const StyledBreadcrumbScrollingContainer = styled.div`
    margin: 0;
    width: 100%;
    display: inline-block;
    white-space: nowrap;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
    overflow-x: auto;
    overflow-y: hidden;

    ::-webkit-scrollbar {
        display: none !important;
    }
`;
