export const escapeQuotes = (str: string = '') => {
    let cleanString = JSON.stringify(str);
    if (cleanString.charAt(0) === '"') {
        cleanString = cleanString.slice(1);
    }
    if (cleanString.charAt(cleanString.length - 1) === '"') {
        cleanString = cleanString.slice(0, cleanString.length - 1);
    }
    return cleanString;
};
