import * as React from 'react';
import { FormattedMessage } from '@liveauctioneers/hammer-ui-core/intl';
import { getBidderReviewLocation } from '@/utils/address';
import { getImageUrl } from '@/utils/urls';
import { getIsWhiteLabel, getWhiteLabelPrimaryColor } from '@/redux/modules/whiteLabel';
import { Info } from '@liveauctioneers/hammer-ui-core/icons/v2';
import { ReviewComment, ReviewResponse } from '@/types/Review';
import { Seller } from '@/types/Seller';
import { shortFormatDate } from '@liveauctioneers/caterwaul-components/lib/utils/dates';
import { useAppSelector } from '@/redux/hooks';
import isEqual from 'react-fast-compare';
import RatingDisplaySmall from '@/components/RatingDisplaySmall/RatingDisplaySmall';
import ReviewAndComments from './ReviewAndComments';
import styled, { css, useTheme } from 'styled-components';
import Text from '@liveauctioneers/caterwaul-components/lib/Text/Text';
import Tooltip, { Header, Message } from '@liveauctioneers/caterwaul-components/lib/Tooltip/Tooltip';
import useIsNarrowerThan from '@/hooks/useIsNarrowerThan';

export interface ReviewProps {
    comments: ReviewComment[];
    helpfulCount: number;
    houseReview: ReviewResponse;
    seller: Seller;
    statuses: string[];
    submitted: boolean;
}

const getDisplayTime = (time: string) => shortFormatDate(new Date(time));

const Review = ({ comments, helpfulCount, houseReview, seller, statuses, submitted }: ReviewProps) => {
    const {
        breakpoints: { tabletNarrowWidthNum },
    } = useTheme();
    const mobile = useIsNarrowerThan(tabletNarrowWidthNum + 1);

    const {
        bidderCity,
        bidderCountry,
        bidderFirstName,
        bidderState,
        catalogId,
        overall: rating,
        review,
        reviewHeadline,
        reviewId,
        reviewSubmitted,
        wonItems,
    } = houseReview;

    const isWhiteLabel = useAppSelector(getIsWhiteLabel);
    const whiteLabelPrimaryColor = useAppSelector(getWhiteLabelPrimaryColor);

    const imageSize = !mobile ? 94 : 67;
    const imageCount = !mobile ? 3 : 1;

    const images = wonItems
        ? wonItems.slice(0, imageCount).map((item, index) => {
              return (
                  <img
                      alt={item.title}
                      key={index}
                      loading="lazy"
                      src={getImageUrl({
                          catalogId: catalogId,
                          height: imageSize,
                          itemId: item.itemId,
                          sellerId: seller.sellerId,
                      })}
                  />
              );
          })
        : [];

    const imageView = <StyledImageContainer $isMobile={mobile}>{images}</StyledImageContainer>;

    const bidder = (
        <StyledReviewBidderText
            $isMobile={mobile}
            h3
        >
            {bidderFirstName}
            <Tooltip
                placement="bottom"
                trigger={
                    <Info
                        className="mx-2xs -mt-2xs inline-block"
                        color="secondary"
                        customStyles={{ color: isWhiteLabel ? whiteLabelPrimaryColor : 'inherit' }}
                        size="sm"
                        style="fill"
                    />
                }
            >
                <Header>
                    <FormattedMessage id="review.verified_buyer_headline" />
                </Header>
                <Message last>
                    <FormattedMessage id="review.verified_buyer_text" />
                </Message>
            </Tooltip>
            <StyledCityText>{getBidderReviewLocation(bidderCity, bidderState, bidderCountry)}</StyledCityText>
        </StyledReviewBidderText>
    );

    const ratingsAndDate = (
        <div>
            <StyledBidderRatingContainer>
                <RatingDisplaySmall
                    hideReviewCount
                    rating={rating}
                    small
                />
            </StyledBidderRatingContainer>
            <StyledBidderDateContainer>
                <span>{getDisplayTime(reviewSubmitted)}</span>
            </StyledBidderDateContainer>
        </div>
    );

    const reviewMessaging = (
        <ReviewAndComments
            comments={comments}
            helpfulCount={helpfulCount}
            review={review}
            reviewHeadline={reviewHeadline}
            reviewId={reviewId}
            seller={seller}
            statuses={statuses}
            submitted={submitted}
        />
    );

    return mobile ? (
        <MobileReviewContainer>
            {imageView}
            <StyledMobileHeader>
                {bidder}
                {ratingsAndDate}
            </StyledMobileHeader>
            {reviewMessaging}
        </MobileReviewContainer>
    ) : (
        <DesktopReviewContainer data-testid="catalogDesktopReviewCard">
            <StyledLeftSide>{bidder}</StyledLeftSide>
            <StyledRightSide>
                {ratingsAndDate}
                {reviewMessaging}
            </StyledRightSide>
            {imageView}
        </DesktopReviewContainer>
    );
};

export default React.memo<ReviewProps>(Review, isEqual);

const desktopImageContainerStyle = css`
    height: 94px;
    text-align: center;
    width: 94px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border: 1px solid ${({ theme }) => theme.colors.grey400};

    img {
        margin: 0;
        max-width: 100%;
        max-height: 100%;
    }
`;

const mobileImageContainerStyle = css`
    position: absolute;
    right: 0;
    height: 69px;
    width: 69px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid ${({ theme }) => theme.colors.grey400};

    img {
        max-width: 67px;
        max-height: 67px;
    }
`;

const StyledImageContainer = styled.div<{ $isMobile?: boolean }>`
    ${({ $isMobile }) => ($isMobile ? mobileImageContainerStyle : desktopImageContainerStyle)}
`;

const reviewBidderStyleDesktop = css`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 7px;
`;
const reviewBidderStyleMobile = css`
    & > div:first-child {
        display: inline-block;
    }
`;

const StyledReviewBidderText = styled(Text)<{ $isMobile?: boolean }>`
    ${({ $isMobile }) => ($isMobile ? reviewBidderStyleMobile : reviewBidderStyleDesktop)}
`;

const StyledBidderRatingContainer = styled.div`
    display: inline-block;
`;

const StyledBidderDateContainer = styled.div`
    display: inline;
    color: ${({ theme }) => theme.colors.grey100};
    font-size: 16px;
    letter-spacing: 0;
    margin-left: 8px;
    vertical-align: bottom;

    @media (max-width: 400px) {
        margin-left: 0;
    }
`;

const StyledCityText = styled(Text)`
    color: ${({ theme }) => theme.colors.grey100};
    letter-spacing: 0;

    @media (max-width: ${({ theme }) => theme.breakpoints.tabletNarrowWidth}) {
        margin-left: 8px;
    }
`;

const MobileReviewContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 20px;
    padding-bottom: 23px;
    min-height: 140px;
    position: relative;
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey400};
`;

const StyledMobileHeader = styled.div`
    flex-direction: row;
`;

const DesktopReviewContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 20px;
    padding-bottom: 23px;
    min-height: 140px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey400};
`;

const StyledLeftSide = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-width: 132px;
    width: 132px;
`;

const StyledRightSide = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 80%;
    margin-left: 70px;
    margin-right: 10px;

    @media (min-width: ${({ theme }) => theme.breakpoints.tabletNarrowWidthNum + 1}px) and (max-width: 1000px) {
        margin-left: 30px;
    }
`;
