import * as React from 'react';
import { isSubmitted, setBidderReviewStatus } from '@/redux/modules/reviewStatus';
import { openReportReviewModal } from '@/redux/modules/modal';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import Helpful from './Helpful';
import Report from './Report';
import ShouldLogInContainer from '@/components/ShouldLogIn/ShouldLogInContainer';
import styled from 'styled-components';

export interface ReviewFeedbackBarProps {
    helpfulCount: number;
    reviewId: number;
    statuses: string[];
}

const ReviewFeedbackBar = (props: ReviewFeedbackBarProps) => {
    const { helpfulCount, reviewId, statuses } = props;
    const dispatch = useAppDispatch();
    const submitted = useAppSelector((state) => isSubmitted(state));

    const helpfulClick = React.useCallback(() => {
        dispatch(
            setBidderReviewStatus({
                active: !statuses.includes('helpful'),
                reviewId: reviewId,
                reviewStatus: 'helpful',
            })
        );
    }, [dispatch, reviewId, statuses]);

    const reportClickHandler = () => {
        dispatch(openReportReviewModal(reviewId));
    };

    const isHelpful = statuses.includes('helpful');
    const isReported = statuses.includes('reported');

    const reportClick = isReported ? null : reportClickHandler;

    return (
        <FeedbackBar>
            <ShouldLogInContainer
                requireLogin
                runFunction={helpfulClick}
            >
                <StyledHelpful
                    active={isHelpful}
                    count={helpfulCount}
                    key="helpful"
                    onClick={helpfulClick}
                    submitted={submitted}
                />
            </ShouldLogInContainer>
            <ShouldLogInContainer
                requireLogin
                runFunction={reportClick}
            >
                <StyledReportLink
                    active={isReported}
                    key="report"
                    onClick={reportClick}
                />
            </ShouldLogInContainer>
        </FeedbackBar>
    );
};

export default ReviewFeedbackBar;

const FeedbackBar = styled.div`
    align-items: center;
    display: flex;
    margin-top: 14px;
    min-height: 40px;
`;

const StyledHelpful = styled(Helpful)`
    align-items: center;
    display: flex;
    font-size: 16px;
    letter-spacing: 0;
    padding-right: 19px;
    text-decoration: none;

    svg {
        margin: -6px 9px 0 12px;
        font-size: 22px;
    }
`;

const StyledReportLink = styled(Report)`
    border-left: 1px solid ${({ theme }) => theme.colors.grey400};
    font-size: 16px;
    letter-spacing: 0;
    padding-left: 20px;
    text-decoration: none;
`;
